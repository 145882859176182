import request from './config'


// 导入文档
export function uploadDocument(params) {
  return request.post('/admin/excelManage', params)
}

// 查询文档
export function getDocument(params) {
  return request.get('/admin/excelManage', { params })
}


// 删除文档
export function deleteDocument(id) {
  return request.delete(`admin/excelManage/${id}`)
}

// 修改文档状态
export function changeDocumentStatus(id, params) {
  return request.put(`/admin/excelManage/${id}?status=${params.status}`)
}


// 文档详情
export function getDocumentDetail(id, params) {
  return request.get(`/admin/excelManage/content/${id}`, { params })
}

// 文档详情readonly
export function getDocumentDetailReadOnly(id, params) {
  return request.get(`/admin/excelManage/content/readonly/${id}`, { params })
}

// 查询隐藏字段
export function getDocumentDetailHideKey(id) {
  return request.get(`/admin/excelManage/content/setting/${id}`)
}

// 设置隐藏字段
export function changeDocumentDetailHideKey(id, params) {
  return request.put(`/admin/excelManage/content/setting/${id}?head=${params.head}`)
}

// 删除内容
export function deleteDocumentDetail(id, params) {
  return request.delete(`/admin/excelManage/content/${id}`, { params })
}


// 修改内容
export function editDocumentDetail(id, params) {
  return request.put(`/admin/excelManage/content/${id}?content=${params.content}`)
}


// 新增内容
export function addDocumentDetail(params) {
  return request.post(`/admin/excelManage/content`, params)
}

// 新增列
export function addHeadDocumentDetail(params) {
  return request.post(`/admin/excelManage/content/addHead`, params)
}

// 删除列
export function deleteHeadDocumentDetail(params) {
  return request.delete(`/admin/excelManage/content/deleteHead`, { params })
}

// 修改列
export function changeHeadDocumentDetail(params) {
  return request.put(`/admin/excelManage/content/updateHead`, params)
}